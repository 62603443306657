<!--登录界面-->
<template>
  <el-card class="box-card">
    <div slot="header" class="clearfix">
      <h3 class="title">
        <img v-if="specificEntrance.subname !== 'yannan' && specificEntrance.subname !== 'linping'" :src="getLogo(specificEntrance.subname)" />
        <div :class="specificEntrance.subname === 'yannan' ? 'small' : ''">{{ specificEntrance.appName }}</div>
      </h3>
    </div>
    <el-form
      autocomplete="on"
      :model="loginForm"
      :rules="loginRules"
      ref="loginForm"
      label-position="left"
      label-width="0px"
      class="card-box login-form"
    >
      <el-form-item prop="username">
        <span class="svg-container">
          <i class="fa fa-user" />
        </span>
        <el-input
          name="username"
          type="text"
          v-model.trim="loginForm.username"
          autocomplete="on"
          placeholder="邮箱/手机号"
        />
      </el-form-item>
      <el-form-item prop="password">
        <span class="svg-container">
          <i class="fa fa-lock"></i>
        </span>
        <el-input
          name="password"
          :type="pwdType"
          @keyup.enter.native="handleLogin"
          v-model.trim="loginForm.password"
          autocomplete="on"
          placeholder="密码"
        ></el-input>
        <span class="show-pwd" @click="showPwd">
          <i :class="pwdType == 'password' ? 'fa fa-eye-slash' : 'fa fa-eye'" />
        </span>
      </el-form-item>
      <img :src="captcha" height="35" width="80" @click="getCaptcha" />
      <el-form-item prop="code" class="captcha">
        <el-input
          name="code"
          type="text"
          @keyup.enter.native="handleLogin"
          v-model.trim="loginForm.code"
          placeholder="验证码"
        />
      </el-form-item>
      <el-button
        type="primary"
        style="width: 100%"
        :loading="loading"
        @keyup.native.enter="handleLogin"
        @click.native.prevent="handleLogin"
        >登录</el-button
      >
      <!-- <div class="tips">
        <el-button type="text" size="mini">注册?</el-button><el-button type="text" size="mini">忘记密码</el-button>
      </div>-->
    </el-form>
    <a
      href="https://beian.miit.gov.cn"
      target="_blank"
      style="text-decoration: none; display: block; margin-top: 18px"
      ><el-alert
        @click="handleCopyright"
        class="copyright"
        title="© 浙ICP备2021013005号"
        type="info"
        center
        :closable="false"
    /></a>
    <!--<div class="bottom-sheet">-->
    <!--合作伙伴清风侠-->
    <!--</div>-->
  </el-card>
</template>

<script>
// import { loginValidator } from '@/util/validate'
import { stg, getRouter, removeToken, encrypt } from '@/util/index'
import conf from '@/config'
import logo from '@/asset/img/logo.png'
import logoHuaShu from '@/asset/img/huashu.png'
import logoUniCom from '@/asset/img/unicom.png'
import logoADBL from '@/asset/img/adbl.png'
import logoChengdu from '@/asset/img/chengdu.png'
import logoZhxs from '@/asset/img/zhxs.jpg'
import { mapState } from 'vuex'
// import logoJiuLong from "@/asset/img/jiulong.png";
export default {
  name: 'login',
  data() {
    const validatePass = (rule, value, callback) => {
      if (value.length < 5) {
        callback(new Error('密码不能少于5位'))
      } else {
        callback()
      }
    }
    return {
      captcha: '',
      loading: false,
      loginForm: {
        username: '',
        password: '',
        code: '',
        id: ''
      },
      loginRules: {
        username: [
          { required: true, trigger: 'blur' } // , validator: loginValidator
        ],
        password: [
          { required: true, trigger: 'blur', validator: validatePass }
        ],
        code: [{ required: true, trigger: 'blur', message: '请输入验证码' }]
      },
      pwdType: 'password',
      count: 0
    }
  },
  computed: {
    ...mapState(['specificEntrance']),
    PATH_BIGSCREEN() {
      return '/NewBoard'
    },
    PATH_DASHBOARD() {
      return '/dashboard'
    }
    /* captcha() {
      // return conf.baseApi + '/getCaptcha?p=' + this.count
      this.$get('captcha').then(res => {
        this.$message.success('成功删除！')
        console.log(res)
        return res
      })
    }*/
  },
  created() {
    removeToken()
    this.$router.options.routes = []
    this.$router.addRoutes([])
    this.getCaptcha()
    this.$get('notification')
      .then((res) => {
        if (res && res.Val) {
          this.$notify({
            duration: 0,
            title: res.Name,
            dangerouslyUseHTMLString: true,
            message: res.Val
          })
        }
      })
      .catch(() => {})
    if (this.$urlGet.getUrlKey('code') !== null) {
      // this.loginForm.username = ''
      // this.loginForm.password = this.$urlGet.getUrlKey('password')
      // this.loginForm.id = this.$urlGet.getUrlKey('id')
      // this.loginForm.code = this.$urlGet.getUrlKey('code')
      this.handleLoginOne()
    }
    if (this.specificEntrance.verif) {
      this.loginForm.username = this.specificEntrance.verif.username
      this.loginForm.password = this.specificEntrance.verif.password
    }
    // this.$store.dispatch('verifyUserWithoutLogin', this)
  },
  methods: {
    getLogo(name) {
      switch (name) {
        // case "jiulongchuju":
        //   return logoJiuLong;
        case 'wasu':
          return logoHuaShu
        case 'unicom':
          return logoUniCom
        case 'adbl':
          return logoADBL
        case 'chengdu':
          return logoChengdu
        case 'zhxs':
          return logoZhxs
        default:
          return logo
      }
    },
    //展示密码的切换
    showPwd() {
      if (this.pwdType === 'password') {
        this.pwdType = ''
      } else {
        this.pwdType = 'password'
      }
    },
    // 点击图片调用后端的接口重新获取验证码
    getCaptcha() {
      this.count += 1
      this.$get('captcha?p=' + this.count).then((res) => {
        this.captcha = res.data
        this.loginForm.id = res.id
      })
    },
    handleCopyright() {
      console.log(11)
    },

    handleLoginOne() {
      this.$post('loginAction', {
        code: this.$urlGet.getUrlKey('code'),
        noCode: true
      })
        .then((res) => {
          removeToken()
          this.$store.commit('clear')
          stg().setItem('token', res.Token)
          stg().setItem('role', res.Role)
          stg().setItem('router', JSON.stringify(res.Menu))
          stg().setItem('user', JSON.stringify(res.User))
          this.$store.dispatch('getShopInfo', res.User.Info)
          const routers = getRouter(res.Menu)
          this.$router.options.routes = []
          this.$router.options.routes.push(...routers)
          this.$router.addRoutes(routers)
          this.$router.push({ path: '/' })
        })
        .catch((e) => {
          this.getCaptcha()
          this.loading = false
        })
    },
    //处理登录操作 触发登录事件的对象e和可选的目标路径dest
      handleLogin(e, dest = this.PATH_BIGSCREEN) {
      const loginData = Object.assign({}, this.loginForm)
      loginData.password = encrypt(this.loginForm.password, this.loginForm.id)
      this.loading = true
      if (
        this.specificEntrance.subname === 'wasu' ||
        this.specificEntrance.subname === 'chengdu' ||
        this.specificEntrance.subname === 'yannan'
      ) {
        //携带表单的数据向后端loginAction接口发送请求
        this.$post('loginAction', loginData)
          .then((res) => {
            //如果之前登录别的账号移除别的账号存在的token
            removeToken()
            //清空Vuex store中的数据
            this.$store.commit('clear')
            stg().setItem('token', res.Token)
            stg().setItem('role', res.Role)
            stg().setItem('router', JSON.stringify(res.Menu))
            stg().setItem('user', JSON.stringify(res.User))
            this.$store.dispatch('getShopInfo', res.User.Info)
            //根据登录响应中的Menu生成路由配置routers，使用getRouter函数实现
            const routers = getRouter(res.Menu)
            this.$router.options.routes = []
            this.$router.options.routes.push(...routers)
            this.$router.addRoutes(routers)
          })
          .then(() => {
            this.$post('admin/queryBoardDataBaseArea').then((res) => {
              const menus = JSON.parse(stg().getItem('router'))
              if (
                menus.findIndex((item) => {
                  return item.Path === '/NewBoard'//路由跳转到大屏
                }) >= 0
              ) {
                if (typeof res === 'object' && res !== null) {
                  if (res.Typ < 3) {
                    stg().setItem('areaCode', res.Code)
                    stg().setItem('quCode', res.Code)
                    stg().setItem('type', 1)
                    stg().setItem('level', this.getLevel(res.Typ))
                    this.$router.push({
                      path: dest
                    })
                  } else {
                    stg().setItem('areaCode', res.Code)
                    stg().setItem('quCode', res.Code)
                    stg().setItem('type', 0)
                    stg().setItem('level', this.getLevel(res.Typ))
                    if (res.Polyline) { // 判断是否有polyline值，若有则跳转
                      stg().setItem('quPolyline', res.Polyline)
                    } else {
                      stg().setItem('quPolyline', '')
                    }
                    this.$router.push({
                      path: dest
                    })
                  }
                } else {
                  stg().setItem('areaCode', '100000')
                  stg().setItem('quCode', '0')
                  stg().setItem('type', 1)
                  stg().setItem('level', 'country')
                  this.$router.push({
                    path: dest
                  })
                }
              } else {
                this.$router.push({ path: '/' })
              }
            })
          })
          .catch((e) => {
            this.getCaptcha()
            this.loading = false
          })
      } else {
        this.$refs.loginForm.validate((valid) => {
          if (valid) {
            this.$post('loginAction', loginData)
              .then((res) => {
                removeToken()
                this.$store.commit('clear')
                stg().setItem('token', res.Token)
                stg().setItem('role', res.Role)
                stg().setItem('router', JSON.stringify(res.Menu))
                stg().setItem('user', JSON.stringify(res.User))
                this.$store.dispatch('getShopInfo', res.User.Info)
                const routers = getRouter(res.Menu)
                this.$router.options.routes = []
                this.$router.options.routes.push(...routers)
                this.$router.addRoutes(routers)
              })
              .then(() => {
                this.$post('admin/queryBoardDataBaseArea').then((res) => {
                  const menus = JSON.parse(stg().getItem('router'))
                  if (
                    menus.findIndex((item) => {
                      return item.Path === '/NewBoard'
                    }) >= 0
                  ) {
                    if (typeof res === 'object' && res !== null) {
                      if (res.Typ < 3) {
                        stg().setItem('areaCode', res.Code)
                        stg().setItem('quCode', res.Code)
                        stg().setItem('type', 1)
                        stg().setItem('level', this.getLevel(res.Typ))
                        this.$router.push({
                          path: dest
                        })
                      } else {
                        stg().setItem('areaCode', res.Code)
                        stg().setItem('quCode', res.Code)
                        stg().setItem('type', 0)
                        stg().setItem('level', this.getLevel(res.Typ))
                        if (res.Polyline) {
                          stg().setItem('quPolyline', res.Polyline)
                        } else {
                          stg().setItem('quPolyline', '')
                        }
                        if (this.loginForm.username == '盐南高新区') { stg().setItem('quName', '盐南高新区') } else stg().setItem('quName', '')
                        this.$router.push({
                          path: dest
                        })
                      }
                    } else {
                      stg().setItem('areaCode', '100000')
                      stg().setItem('quCode', '0')
                      stg().setItem('type', 1)
                      stg().setItem('level', 'country')
                      this.$router.push({
                        path: dest
                      })
                    }
                  } else {
                    this.$router.push({ path: '/' })
                  }
                })
              })
              .catch((e) => {
                this.getCaptcha()
                this.loading = false
              })
          } else {
            this.getCaptcha()
            this.loading = false
          }
        })
      }
    },
    getLevel(status) {
      switch (status) {
        case 1:
          return 'province'
        case 2:
          return 'city'
        case 3:
          return 'district'
        case 4:
          return 'street'
        case 0:
          return 'country'
      }
    }
  }
}
</script>

<style rel="stylesheet/scss" lang="scss">
// $bg: #2d3a4b;
$dark_gray: #889aa4;
$light_gray: #454545;
body {
  // background-color: #BBD0D3;
  height: 100%;
  background-image: url("~@/asset/img/bg.jpeg");
  background-size: cover;
  // background-position:center;
  background-repeat: no-repeat;
}
.box-card {
  width: 420px;
  margin: 140px auto 0px;
  input:-webkit-autofill {
    box-shadow: 0 0 0px 1000px #293444 inset !important;
    -webkit-text-fill-color: #fff !important;
  }
  input {
    background: transparent;
    border: 0px;
    -webkit-appearance: none;
    border-radius: 0px;
    padding: 12px 5px 12px 15px;
    color: $light_gray;
    height: 35px;
  }
  .el-input {
    display: inline-block;
    height: 35px;
    width: 85%;
  }
  .tips {
    font-size: 14px;
    color: #fff;
    margin-bottom: 10px;
    float: right;
  }
  .svg-container {
    padding: 6px 5px 6px 15px;
    color: $dark_gray;
    vertical-align: middle;
    display: inline-block;
    &_login {
      font-size: 20px;
    }
  }
  .title {
    display: contents;
    font-size: 26px;
    font-weight: 400;
    // color: $light_gray;
    // margin: 0px auto 40px auto;
    line-height: 26px;
    text-align: center;
    font-weight: bold;
    .small {
      font-size: 22px;
    }
    img {
      padding: 15px 0;
      max-height: 160px;
      display: block;
      margin: auto;
    }
  }
  // .login-form {
  //   position: absolute;
  //   left: 0;
  //   right: 0;
  //   width: 400px;
  //   padding: 35px 35px 15px 35px;
  //   margin: 120px auto;
  // }
  .el-form-item {
    border: 1px solid rgba(255, 255, 255, 0.1);
    background: rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    color: #454545;
    margin-bottom: 18px;
  }
  .el-form-item__content {
    line-height: 35px;
  }
  .captcha {
    float: right;
  }
  .show-pwd {
    position: absolute;
    right: 10px;
    top: 7px;
    font-size: 16px;
    color: $dark_gray;
    cursor: pointer;
  }
  .bottom-sheet {
    position: fixed;
    bottom: 15px;
    left: 50%;
    transform: translateX(-50%);
  }
}

// .login {
//   position: relative;
//   top: 100px;
//   right: 0px;
//   bottom: 0px;
//   left: 0px;
//   margin: auto;
// }
</style>
